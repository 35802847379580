import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonIcon, IonGrid, IonCol, IonRow, IonRippleEffect } from '@ionic/react'
import { logoTwitter, logoFacebook,	logoLinkedin, logoInstagram, globe } from 'ionicons/icons'
import { getSocials } from '../../store/actions'
import Layout from '../../components/layout'
import Loading from '../../components/spinner'
import { Title, SmallText, Spacer } from '../../components/common'
import { ucWords, isDefined, isEmptyObject } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import './index.css'
import NoData from '../../components/noData'

const openExternalLink = url => window.open(url, '_system', 'location=yes')

class Social extends Component {
	componentDidMount() {
		this.props.dispatch(getSocials())
	}

	parseSocialItem(key = '', value = '') {
		let label = key
		let icon = key
		let link = value
		let uppercasedKey = key.split('').map((char, i) => i === 0 ? char.toUpperCase() : char).join('')
		switch (uppercasedKey) {
		case 'Facebook':
			icon = logoFacebook
			break
		case 'Twitter':
			icon = logoTwitter
			break
		case 'Instagram':
			icon = logoInstagram
			break
		case 'Linkedin':
			icon = logoLinkedin
			break
		default:
			icon = globe
			break
		}
		return {
			link,
			icon,
			label
		}
	}

	render() {
		const { __, social } = this.props
		return (
			<Loading>
				<Layout color='primary' headerTitle={ __('Social Media') }>
					<div className="header-wrapper">
						<Title>{ __('Social Media') }</Title>
						<SmallText color="primary" className="heading thiner-text">{ __('Get the latest info and offers by following us on our social media channels') }</SmallText>
					</div>
					<Spacer size={ 2 }/>
					<div className="frm-wrapper">
						{ isDefined(social) && !isEmptyObject(social) ?
							<IonGrid className="no-padding social">
								<IonRow>
									{ Object.keys(social || {}).map((key, index) => {
										const value = social[key]
										const { link, icon, label } = this.parseSocialItem(key, value)
										const capitalizeLabel = ucWords(label)
										return <IonCol key={ 'soc-item-' + index } size="12">
											<div className="square ion-activatable" onClick={ () => openExternalLink(link) }>
												<div className="content">
													<div className="soc-icon">
														<IonIcon icon={ icon } color="white" />
													</div>
													<SmallText color="primary" className="soc-label uppercase bold">{ __(capitalizeLabel) }</SmallText>
												</div>
												<IonRippleEffect></IonRippleEffect>
											</div>
										</IonCol>
									})}
								</IonRow>
							</IonGrid>
							:
							<NoData label={ __('No social networks') }/>
						}
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { social } = state.common
	return {
		social
	}
}

export default connect(stateToProps)(withTranslation(Social))
